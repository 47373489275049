<template>
  <div class="content">
    <heads></heads>
    <div style="padding: 30px 120px;">
      <div class="main">
        <div class="main-head">
          <div class="record-flex" @click="jumpRecord">
            <img class="record-img" src="../../assets/market/jl.png" alt="">
            <div class="record">{{ $t('assets.jl') }}</div>
          </div>
          <div class="assets-flex">
            <div class="my-assets">{{ $t('assets.tit') }}</div>
            <div class="line"></div>
            <div>
              <div class="assets-usdt">{{ $t('assets.zh') }}(USDT)</div>
              <div class="num">{{total}} USDT </div>
            </div>
          </div>
        </div>
        <div class="quotation-box">
          <div class="quotation-box-head">
            <div style="display: flex;align-items: center;">
              <div class="quotation-box-nav quotation-active">
                <div class="quotation-box-tit">{{ $t('assets.qb') }}</div>
              </div>
            </div>

          </div>
          <div class="quotation-list">
            <div class="quotation-list-tit">
              <div>{{ $t('assets.bz') }}</div>
              <div>{{ $t('assets.kyye') }}</div>
              <div>{{ $t('assets.djse') }}</div>
              <div>USDT{{ $t('assets.gz') }}</div>
              <div style="text-align: right;">{{ $t('assets.cz') }}</div>
            </div>
          </div>
          <div class="quotation-list" v-for="(item, index) in list" :key="index">
            <div class="quotation-list-tit">
              <div class="fs-16" style="color: #FFFFFF;">{{ item.coin_name }}</div>
              <div>{{(item.num*1).toFixed(6)}}</div>
              <div>{{(item.lock_num*1).toFixed(6)}}</div>
              <div>{{(item.usdt_num*1).toFixed(6)}}</div>
              <div class="set-flex">
                <span style="margin-right: 6px;" :style="{'color':(item.coin_id===2 || item.coin_id===3)?'#FF6B65':'rgba(255, 255, 255, 0.6)'}" @click="jumpCb(item.coin_id)">{{ $t('assets.cb') }}</span>
                <span class="line2"></span>
                <span style="margin-right: 6px;" :style="{'color':(item.coin_id===2 || item.coin_id===3)?'#43CF7C':'rgba(255, 255, 255, 0.6)'}"  @click="jumpTb(item.coin_id)">{{ $t('assets.tb') }}</span>
                <span class="line2" v-if="item.coin_id!=2"></span>
                <span style="color: #FFC300;" @click='toJY(item)' v-if="item.coin_id!=2">{{ $t('assets.qjy') }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import router from '../../router';
import {getCoinList} from "@/api/money";
export default {
  data() {
    return {
      getData: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
      name: '',
      list:[],
      total:''
    }
  },
  components: {
    footerb,
    heads
  },
  created(){
    this.getList()
  },
  methods: {
    jumpRecord() {
      this.$router.push('./assetsRecord')
    },
    getList() {
      getCoinList().then(res => {
        if (res.code === 1) {
          this.list = res.data.items
          this.total = res.data.totalUsdt
        }
      })
    },
    jumpCb(coinId){ //充币
      if(coinId!==2 && coinId!==3){
        return
      }
      this.$router.push('/recharge?coinId='+coinId)
    },
    jumpTb(coinId){ //提币
      if(coinId!==2 && coinId!==3){
        return
      }
      this.$router.push('/withdraw?coinId='+coinId)
    },
    toJY(item){
      this.$router.push({path:`/transaction?id=${item.coin_id}&coin_name=${item.coin_name}`})
    },
  }
};

</script>
<style scoped>
html {
  background: #171e28;
}
 .fs-16{
   font-size: 16px;
 }
.main {
  width: 100%;
  height: auto;
  background: #1F282F;
  padding: 30px;
  box-sizing: border-box;
}

.main-head {
  width: 100%;
  height: auto;
  background: #171E28;
  margin-bottom: 20px;
}

.record-img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.record {
  font-size: 14px;
  color: #FFC300;
}

.record-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
  padding-top: 15px;
  cursor: pointer;
}

.line {
  width: 1px;
  height: 102px;
  background: #29343D;
  margin-right: 50px;
}

.assets-flex {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  padding-bottom: 30px;
}

.my-assets {
  margin-right: 50px;
  font-size: 36px;
  margin-left: 50px;
}

.assets-usdt {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 8px;
}

.num {
  font-size: 24px;
}

.quotation-box {
  width: 100%;
  height: auto;
  background: #171E28;
  padding: 28px 0 16px 0;
  margin-bottom: 30px;
}

.quotation-box-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.quotation-box-tit {
  font-size: 16px;
  /* color: #FFFFFF; */
}

.quotation-box-nav {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  color: rgba(255, 255, 255, 0.6);
}

.quotation-active {
  border-bottom: 1px solid #FFC300;
  color: #FFC300 !important;
}

.quotation-box-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #1F282F;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 40px;
  box-sizing: border-box;
}

.quotation-list {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #1F282F;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.quotation-list-tit {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 40px;
}

.quotation-list-tit div {
  width: 20%;
  font-size: 14px;
}

.line2 {
  width: 1px;
  height: 12px;
  background: #29343D;
  display: inline-block;
  margin-right: 6px;
}

.set-flex {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
</style>
